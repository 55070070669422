import { Link } from "react-router-dom";
import "../styles/bookings.css";
import Sidebar from "../components/Sidebar/Sidebar";
import TopNav from "../components/TopNav/TopNav";
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

// import { fetchRequest, fetchSuccess, fetchError } from '../reducers/Action';

import axios from "axios"
// import { useSelector, useDispatch } from 'react-redux'






const SaleByProduct = () => {
    const navigate = useNavigate()
    // const sale = useSelector(state => state.values)
    // const dispatch = useDispatch()
    const [sale, setSale] = useState([])
    console.log(sale);
    const [query, setQuery] = useState('')
    const [total, setTotal] = useState('')

    const handle = (e) => {
        const newCustomer = { ...SCustomer }
        newCustomer[e.target.id] = e.target.value;
        setSCustomer(newCustomer)
        console.log(newCustomer);
    }


    useEffect(() => {

        const params = new FormData();

        params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');

        const config = {

            headers: {

                "Content-Type": "application/x-www-form-urlencoded"

            }

        }

        // dispatch(fetchRequest)

        axios.post('https://apis.mashallahmazangparatha.pk/fetch_product_sale_report.php', params, config).then((res) => {

            console.log(res.data)
                const sale = res.data.data.report
                setSale(sale)
                setTotal(res.data.data.total)
                // dispatch(fetchSuccess(sale))

            }

        )

    }, [])


    const [datecustomer, setDateCustomer] = useState([])

    const [SCustomer, setSCustomer] = useState({
        Cstart_date: '',
        Cend_date: '',
    })

    const [newBill,setNewBill]=useState()

    const customerSearch = () => {
        document.getElementById('Rcustomer').classList.add('d-none')
        document.getElementById('Rdcustomer').classList.remove('d-none')
        // document.getElementById('Rdcustomer').classList.add('d-block')


        const params = new FormData();
        params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
        params.append('start_date', SCustomer.Cstart_date);
        params.append('end_date', SCustomer.Cend_date);

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }

        axios.post('https://apis.mashallahmazangparatha.pk/fetch_secific_product_sale_report.php', params, config).then((res) => {
                console.log(res.data)
                const sale = res.data.data?.report
                setDateCustomer(sale)
                setNewBill(res.data.data?.total)
                console.log(datecustomer, 'date customer');
            }
        )

    }


    return (
        <>
            <div className="layout">
                <Sidebar />
                <div className="main__layout">
                    <TopNav />

                    <div className="content" style={{ marginLeft: '128px' }} >
                        <div className="bookings mb-5">
                            <div className="booking__wrapper p-5">
                                <h2 className="booking__title">Reports</h2>
                                <div className="d-flex justify-content-between">

                                    <div>
                                        <ul className="d-flex p-0 gx-5">
                                            <li>
                                                <Link className="float-end btn_sve border-0 py-1 padd-1 text-white rounded " to="/reports">Sales Report</Link>
                                            </li>
                                            <li className="ps-3">
                                                <Link className="float-end btn_sve border-0 py-1 padd-1 text-white rounded " to="/profit">Consumption Report</Link>
                                            </li>
                                            <li className="px-2">
                                                <Link className="float-end background-orange  border-0  dt-save text-white rounded " to="/sale">Sale by Product </Link>
                                            </li>
                                        </ul>
                                    </div>

                                    <div>
                                        <div className='d-flex gap-3'>
                                            <form className="form-floating p-0">
                                                <input type="datetime-local" className="form-control abc" id="Cstart_date" onChange={(e) => {
                                                    handle(e)
                                                }}/>
                                                <label htmlFor="Cstart_date">From</label>
                                            </form>
                                            <form className="form-floating">
                                                <input type="datetime-local" className="form-control abc" id="Cend_date" onChange={(e) => {
                                                    handle(e)
                                                }}/>
                                                <label htmlFor="Cend_date">To</label>
                                            </form>
                                            <button className='btn btn-success border-0 py-2 text-white rounded search-btn'
                                                    onClick={customerSearch}>Search
                                            </button>
                                        </div>
                                    </div>

                                </div>

                                <input type="search" placeholder="search..." className="tables_serach rounded" onChange={e => setQuery(e.target.value)} />

                                <div id='Rcustomer'>
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">id_</th>
                                            <th scope="col" > Product Name</th>
                                            <th scope="col" className={"text-end"}>Price</th>
                                            <th scope="col" className={"text-end"}> Quantity</th>
                                            <th scope="col" className={"text-end"}> Total</th>
                                            <th scope="col" className={"text-end"}> Date</th>
                                        </tr>
                                        </thead>
                                        {sale?.filter(i => {
                                            if (query === '') {
                                                return i
                                            } else if (i?.name?.toLowerCase()?.includes(query.toLocaleLowerCase())) {
                                                return i
                                            }
                                        })?.map((i, index) => {
                                            return (
                                                <tbody>
                                                <tr>
                                                    <th scope="row">{index}</th>
                                                    <td>{i?.product_name}</td>
                                                    <td className={"text-end"}>{i?.product_price}</td>
                                                    <td className={"text-end"}>{i?.quantity}</td>
                                                    <td className={"text-end"}>{i?.quantity * i?.product_price}</td>
                                                    <td className={"text-end"}>{i?.created_at}</td>
                                                </tr>

                                                </tbody>
                                            )
                                        })}


                                    </table>
                                    <div className={'text-white '}>
                                        <h4>Grand Total: {total}</h4>
                                    </div>
                                </div>


                                <div className={'d-none'} id='Rdcustomer'>
                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th scope="col">id_</th>
                                            <th scope="col"> Product Name</th>
                                            <th scope="col" className={"text-end"}>Price</th>
                                            <th scope="col" className={"text-end"}> Quantity</th>
                                            <th scope="col" className={"text-end"}> Total</th>
                                            <th scope="col" className={"text-end"}> Date</th>
                                        </tr>
                                        </thead>
                                        {datecustomer?.filter(i => {
                                            if (query === '') {
                                                return i
                                            } else if (i?.name?.toLowerCase()?.includes(query.toLocaleLowerCase())) {
                                                return i
                                            }
                                        })?.map((i, index) => {
                                            return (
                                                <tbody>
                                                <tr>
                                                    <th scope="row">{index}</th>
                                                    <td >{i?.product_name}</td>
                                                    <td className={"text-end"}>{i?.product_price}</td>
                                                    <td className={"text-end"}>{i?.quantity}</td>
                                                    <td className={"text-end"}>{i?.quantity * i?.product_price}</td>
                                                    <td className={"text-end"}>{i?.created_at}</td>
                                                </tr>

                                                </tbody>
                                            )
                                        })}


                                    </table>
                                    <div className={'text-white '}>
                                        <h4>Grand Total: {newBill}</h4>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    );
};

export default SaleByProduct;
