
import "../styles/settings.css";
import Sidebar from "../components/Sidebar/Sidebar";
import TopNav from "../components/TopNav/TopNav";
import React, { useState,useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from "axios"



const UpdateMaterial = () => {

    const {id}=useParams()


    const navigate=useNavigate();


    const [addMaterial, setAddMaterial] = useState({
        name:'',
        price:'',
        quantity:'',
        unit:'',
    })


    const [materials, setMaterials] = useState({})





    const handle=(e)=>{
        const newMaterial={...addMaterial}
        newMaterial[e.target.id]=e.target.value
        setAddMaterial(newMaterial)
        console.log(newMaterial);
    }











    const submit=(e)=>{
        e.preventDefault();

        const params = new FormData();
        params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
        params.append('name',document.getElementById('name').value)
        params.append('material_uid',id)
        params.append('price',document.getElementById('price').value)
        params.append('quantity',document.getElementById('quantity').value)
        params.append('unit','gram')

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }


        axios.post('https://apis.mashallahmazangparatha.pk/edit_material.php', params, config).then((res) => {
                if(res.data.state==='OK'){
                    navigate('/materials')
                }

            }

        )}




    useEffect(() => {

        const params = new FormData();
        params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
        params.append('material_uid',id)

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }


        axios.post('https://apis.mashallahmazangparatha.pk/fetch_material.php', params, config).then((res) => {
                console.log(res.data,'=====>check');

                const mater = res.data.data.material;

                setMaterials(mater)
            }

        )

    }, [])








    return (
        <div className="layout">
            <Sidebar />
            <div className="main__layout">
                <TopNav />

                <div className="content"  style={{marginLeft: '128px'}} >
                    <div className="settings">
                        <div className="settings__wrapper">
                            <h2 className="settings__title">Update Material</h2>


                            <div className="details__form">

                                <form>
                                    <div className="form__group">
                                        <div>
                                            <label>Name</label>
                                            <input type="text" id='name' placeholder="Enter Material Name.." onChange={(e)=>handle(e)} defaultValue={materials?.name}/>
                                        </div>


                                        <div className="pt-3">
                                            <label>Price</label>
                                            <input type="text" id="price" placeholder="Enter Material Price..." onChange={(e)=>handle(e)} defaultValue={materials?.price} required/>
                                        </div>


                                        <div className="pt-3">
                                            <label>Quantity</label>
                                            <input type="text" id="quantity" placeholder="Enter Material Quantity..." onChange={(e)=>handle(e)} defaultValue={materials?.quantity} required/>
                                        </div>


                                        <div className="pt-3">
                                            <label>Unit</label>
                                            <input type="text" id="unit" placeholder="Enter Material Unit..." onChange={(e)=>handle(e)} defaultValue={materials?.unit} required/>
                                        </div>

                                        <div>

                                            <button type="submit" className="w-50  login_btn border-0 rounded mt-5 py-3" placeholder="SYL 3108" onClick={submit}>Update Material</button>
                                        </div>
                                    </div>





                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default UpdateMaterial;
