
const waiterNavLinks = [
    {
        path: "/waiter-dashboard",
        icon: "ri-apps-2-line",
        display: "Dashboard",
    },
    {
        path: "/bookings",
        icon: "ri-store-3-line",
        display: "Pos",
    },  {
        path: "/menus",
        icon: "ri-store-3-line",
        display: "Menus",
    }

];
export default waiterNavLinks;