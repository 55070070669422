import {Link, useNavigate, useParams} from "react-router-dom";
import "../styles/bookings.css";
import Sidebar from "../components/Sidebar/Sidebar";
import TopNav from "../components/TopNav/TopNav";
import React, { useEffect } from 'react'
import axios from "axios"
import { useState } from "react";
import {message,Modal} from "antd";

const MenuProducts = () => {
    const navigate=useNavigate()
    const [materials,setMaterials] = useState([])
    const {id}=useParams()

    useEffect(() => {


        const params = new FormData();
        params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
        params.append('menu_uid', id);
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }

        axios.post('https://apis.mashallahmazangparatha.pk/fetch_menu_products.php', params, config).then((res) => {
            console.log(res.data)
            const materials = res.data.data.product;
                setMaterials(materials)
            }
        )

    }, [])

    const onDeleteStudent = (id)=>{
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk:()=>{
                const params = new FormData();
                params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
                params.append('menu_product_uid', id)

                const config = {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }


                axios.post('https://apis.mashallahmazangparatha.pk/delete_menu_product.php', params, config).then(res => {
                    console.log(res.data);
                    if (res.data.data.deleted === true) {
                        message.success("Deleted Successfully")
                        window.location.reload(true)
                    }
                })
            }

        })
    }
    return (
        <>
            <div className="layout">
                <Sidebar />
                <div className="main__layout">
                    <TopNav />

                    <div className="content"  style={{marginLeft: '128px'}} >
                        <div>
                            <div className="add_products mt-5">
                                <Link to='/add-menu-product'>
                                    <i class="ri-add-line"/>
                                </Link>

                            </div>
                        </div>
                        <div className="bookings mb-5">
                            <div className="booking__wrapper p-5">
                                <h2 className="booking__title">Menu Product</h2>





                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">id_</th>
                                        {/* <th scope="col">Image</th> */}
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                    </thead>
                                    {materials.map((i,index)=>{
                                        return(
                                            <tbody>
                                            <tr>
                                                <td scope="row">{index}</td>
                                                {/* <td>  <img width='20px' className="table_img" src={require('../assets/images/profile-02.png')} />  </td> */}
                                                <td className="d-flex">{i.product_name}</td>
                                                <td>{i.product_price}</td>                                                <td className="d-flex gap-4">
                                                    <i className="bi bi-pencil-square text-white" style={{cursor: 'pointer'}} onClick={() => { navigate(`/updtaeMaterial/${i.uid}`) }}/>
                                                    <i className="bi bi-trash3-fill text-danger" style={{cursor: 'pointer'}} onClick={() => {
                                                        onDeleteStudent(i.menu_product_uid)
                                                    }}/>
                                                </td>
                                            </tr>

                                            </tbody>
                                        )})}
                                </table>





                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    );
};

export default MenuProducts;
