import React from "react";
import { Link, NavLink } from "react-router-dom";
import navLinks from "../../assets/dummy-data/navLinks";
import waiterNavLinks from "../../assets/dummy-data/waiterNavLinks";
import "./sidebar.css";

const Sidebar = () => {
    const isLoggedInAsWaiter = localStorage.getItem('login') === 'waiter';
    const isLoggedInAsAdmin = localStorage.getItem('login') === 'admin';
  return (
    <div className="sidebar">
      <div className="sidebar__top my-3 px-4">
        <h2 className=" ">

           <img src={require('../../assets/images/Mashallah Mazang Paratha Final Logo.png')} className='log-pos' alt={"not found"}/>


        </h2>
      </div>

      <div className="sidebar__content mb-5">
        <div className="menu">

              {isLoggedInAsWaiter && (
                  <ul className="nav__list">
                      {waiterNavLinks.map((item, index) => (
                          <li className="nav__item" key={index}>
                              <NavLink
                                  to={item.path}
                                  className={(navClass) =>
                                      navClass.isActive ? "nav__active nav__link" : "nav__link"
                                  }
                              >
                                  <i className={item.icon}/>
                                  {item.display}
                              </NavLink>
                          </li>
                      ))}
                  </ul>
              )}

            {isLoggedInAsAdmin && (
                <ul className="nav__list">
                    {navLinks.map((item, index) => (
                        <li className="nav__item" key={index}>
                            <NavLink
                                to={item.path}
                                className={(navClass) =>
                                    navClass.isActive ? "nav__active nav__link" : "nav__link"
                                }
                            >
                                <i className={item.icon}/>
                                {item.display}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            )}

        </div>

        <div className="sidebar__bottom">
        <NavLink to="/" >

        <span>
            <i class="ri-logout-circle-r-line"/> Logout
          </span>
        </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
