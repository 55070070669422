import "../styles/settings.css";
import Sidebar from "../components/Sidebar/Sidebar";
import TopNav from "../components/TopNav/TopNav";
import React, {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from "axios"
import {message} from "antd";


const AddMenuProduct = () => {


    const navigate = useNavigate();


    const [addMaterial, setAddMaterial] = useState({
        name: '',

    })

    const [menus, setMenus] = useState([]);
    const [products, setProducts] = useState([]);

    const [selectedMenuId, setSelectedMenuId] = useState('');
    const [selectedProductId, setSelectedProductId] = useState('');

    useEffect(() => {
       fetchMenus();
       fetchProducts();

    }, []);


    const fetchMenus = () =>{
        const params = new FormData();

        params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }
        axios.post('https://apis.mashallahmazangparatha.pk/fetch_menus.php', params, config).then((res) => {
                setMenus(res.data.data.menus);

            }
        )
    }
    const fetchProducts = () => {

        const params = new FormData();

        params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }
        axios.post('https://apis.mashallahmazangparatha.pk/fetch_products.php', params, config).then((res) => {
            setProducts(res.data.data.products);

            }
        )
    }


    const handle = (e) => {
        const newMaterial = {...addMaterial}
        newMaterial[e.target.id] = e.target.value
        setAddMaterial(newMaterial)
        console.log(newMaterial);
    }

    const params = new FormData();

    params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
    params.append('menu_uid', selectedMenuId)
    params.append('product_uid', selectedProductId)
    const config = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    }

    const submit = (e) => {
        e.preventDefault();
            axios.post('https://apis.mashallahmazangparatha.pk/add_menu_product.php', params, config).then((res) => {
                    console.log(res.data)
                message.success("Product added")
                }
            )

    }


    return (
        <div className="layout">
            <Sidebar/>
            <div className="main__layout">
                <TopNav/>

                <div className="content" style={{marginLeft: '128px'}}>
                    <div className="settings">
                        <div className="settings__wrapper">
                            <h2 className="settings__title">Add Menu Products</h2>


                            <div className="details__form">

                                <form>
                                    <div className="form__group">

                                        <div>
                                            <label>Select Menu</label>
                                            <select
                                                onChange={(e)=>setSelectedMenuId(e.target.value)}
                                            >
                                                {menus.map((menu) => (
                                                    <option key={menu.uid} value={menu.uid}>
                                                        {menu.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div>
                                            <label>Select Product</label>
                                            <select
                                            onChange={(e)=>setSelectedProductId(e.target.value)}
                                            >
                                                {products.map((product) => (
                                                    <option key={product.uid} value={product.uid}>
                                                        {product.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div>

                                            <button type="submit" className="w-50  login_btn border-0 rounded mt-5 py-3"
                                                    placeholder="SYL 3108" onClick={submit}>Add Menu
                                            </button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AddMenuProduct;
