
import React, { useEffect,useMemo } from 'react'

import { fetchRequest, fetchSuccess, fetchError } from '../reducers/Action';

import axios from "axios"
import { useSelector, useDispatch } from 'react-redux'
import {Link, useParams} from 'react-router-dom';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'

const Slip = () => {
  const navigate=useNavigate()

    const {id}=useParams()


   const [sales,setSale] = useState([])
   const [prodss,setProdss] = useState([])

    function printSlip() {
        document.getElementById('slip').innerHTML='Thanks for visiting us!'
        window.print()
        document.getElementById('slip').setAttribute('disabled','true')


    }








  const path=localStorage.getItem('slip_uid')

    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDateTime = `${day}/${month}/${year} ${hour}:${minute}:${second}`;


  const GetSlip=async()=>{

    const params = new FormData();

    params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
    params.append('sale_uid',id)

    const config = {

      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }

    }

    // dispatch(fetchRequest)

    await axios.post('https://apis.mashallahmazangparatha.pk/fetch_bill_transcript.php', params, config).then((res) => {

    console.log(res.data,'=======>slip');

      const sale =  res.data?.data?.sale[0]
      const prod1 = res.data?.data?.products
      setSale(sale)
      setProdss(prod1);


    }

    )

  }



  useEffect(() => {

    GetSlip()

  }, [])




const bills = localStorage.getItem("bill");















  return (
    <div className=' slipss  rounded  py-1 mx-5'  style={{background:'white',overflow:'hidden'}}>
        <div className=''>
           <Link to="/bookings" ><button className='btn_sve  border-0 py-1 text-dark rounded text-white'>  Go Back</button></Link>
        </div>
       <div className="row">
        <div className="col-md-10  m-0 p-0 mx-auto col-sm-12" style={{width:'94%'}}>
        <div className='border  p-0 border-0 p-1'>
            <div  className='text-center'>
                <img src={require('../assets/images/Mashallah Mazang Paratha Final Logo.png')} alt="logo"  className='img-fluid  slips_img'  style={{width:'20px'}} />
            </div>
            <div className='m-2 text-dark  tre  text-center' style={{fontWeight:'bold'}} >
                <p className='m-0' style={{fontSize:"10px"}} >F67V+3R6, Ali Town Rd, علی ٹاؤن Ali Town, لاہور, Lahore, پنجاب</p>
                <p className='m-0' style={{fontSize:"10px"}}  >+92123-78748378</p>

            </div>


            <div className='text-end'>
                <label style={{fontWeight:'bold'}} className='text-dark pt-1  times'>{`Date: ${currentDateTime}`}</label>
            </div>

            <div className=' tables_ss   text-start pt-2' >
                <table className='text-dark text-end w-100'>
                    <thead className='second-bills' style={{borderBottom:'1px solid black'}}>
                    <th className='border-0 text-center add_items '   style={{fontSize:"10px"}}  >Items</th>
                        <th className='border-0 text-center add_items '  style={{fontSize:"10px"}}  >Quantity</th>
                        <th className='border-0 text-center add_items'  style={{fontSize:"10px"}} >Price</th>
                    </thead>

                    <tbody>

                    {prodss?.map((i,index)=>{
                  return(
                        <tr className='  '>
                            <td className='text-center' >{i?.name}</td>
                            <td  className='text-center'>{i?.quantity}</td>
                            <td  className='text-center' >{i?.bill}</td>
                        </tr>
                        )})}
                    </tbody>
                </table>




            </div>

            <div style={{borderTop:'1px solid black'}} >
                <h6 className='text-dark float-end total_bills'>{`Total Bill: ${sales?.bill}/Rs-`}</h6>
            </div>

            <div>
            <div className='  text-dark  tre text-center' style={{fontWeight:'bold'}} >

                <p className='m-0 text-center'  style={{fontSize:"10px"}}  >Thank you for visiting us.</p>
                <p className='m-0' style={{fontSize:"10px"}} >Software By: Development</p>
                <p className='m-0' style={{fontSize:"10px"}} >+92-123-1234454</p>




            </div>
            </div>


            <div>
                <button className=' text-white float-end btn_sve border-0 py-1 text-dark rounded ' id='slip' onClick={printSlip}>Print</button>
            </div>


        </div>
        </div>
       </div>
    </div>
  )
}

export default Slip
