
import "../styles/settings.css";
import Sidebar from "../components/Sidebar/Sidebar";
import TopNav from "../components/TopNav/TopNav";
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from "axios"
import { message } from "antd";


const AddMenu = () => {


    const navigate=useNavigate();


    const [addMaterial, setAddMaterial] = useState({
        name:'',

    })





    const handle=(e)=>{
        const newMaterial={...addMaterial}
        newMaterial[e.target.id]=e.target.value
        setAddMaterial(newMaterial)
        console.log(newMaterial);
    }










    const params = new FormData();

    params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
    params.append('name',addMaterial.name)


    const config = {

        headers: {

            "Content-Type": "application/x-www-form-urlencoded"

        }

    }






    const submit=(e)=>{
        e.preventDefault();


        if(document.getElementById('name').value.length===0){
            message.success("Must enter name")
        } else{

            axios.post('https://apis.mashallahmazangparatha.pk/add_menu.php', params, config).then((res) => {

                    console.log(res.data)
                message.success("Menu added")
                }
            )

        }


    }










    return (
        <div className="layout">
            <Sidebar />
            <div className="main__layout">
                <TopNav />

                <div className="content"  style={{marginLeft: '128px'}} >
                    <div className="settings">
                        <div className="settings__wrapper">
                            <h2 className="settings__title">Add Menu</h2>


                            <div className="details__form">

                                <form>
                                    <div className="form__group">
                                        <div>
                                            <label>Name</label>
                                            <input type="text" id='name' placeholder="Enter Menu Name.." onChange={(e)=>handle(e)} value={addMaterial.name}/>
                                        </div>


                                        <div>

                                            <button type="submit" className="w-50  login_btn border-0 rounded mt-5 py-3" placeholder="SYL 3108" onClick={submit}>Add Menu</button>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default AddMenu;
