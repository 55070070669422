import { Link, useNavigate } from "react-router-dom";
import "../styles/bookings.css";
import Sidebar from "../components/Sidebar/Sidebar";
import TopNav from "../components/TopNav/TopNav";
import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { message, Modal } from "antd";

const Products = () => {
  const navigate = useNavigate();

  const [materials, setMaterials] = useState([]);
  const [disableProduct, setDisableProduct] = useState([]);

  useEffect(() => {
    const params = new FormData();

    params.append(
      "__api_key__",
      "hi,-its-eevee. I can do wonderful things in api creation."
    );

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
      .post(
        "https://apis.mashallahmazangparatha.pk/fetch_products.php",
        params,
        config
      )
      .then((res) => {
        const mater = res.data.data.products;

        setMaterials(mater);
      });

  }, []);



  useEffect(() => {
    const params = new FormData();

    params.append(
      "__api_key__",
      "hi,-its-eevee. I can do wonderful things in api creation."
    );

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    axios
        .post(
          "https://apis.mashallahmazangparatha.pk/fetch_disable_products.php",
          params,
          config
        )
        .then((res) => {
          console.log(res.data);
          const mater = res.data.data.disable_products;
  
          setDisableProduct(mater);
        });

  }, []);



  const onDeleteStudent = (id) => {
    Modal.confirm({
      title: "Are you sure you want to update state?",
      onOk: () => {
        const params = new FormData();
        params.append(
          "__api_key__",
          "hi,-its-eevee. I can do wonderful things in api creation."
        );
        params.append("product_uid", id);

        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        };

        axios
          .post(
            "https://apis.mashallahmazangparatha.pk/toggle_product_enable_disable.php",
            params,
            config
          )
          .then((res) => {
            console.log(res.data,'disable');
            if (res.data.data.updated === true) {
              message.success("Product Update Successfully");
              window.location.reload(true);
            }
          });
      },
    });
  };

  
  return (
    <>
      <div className="layout">
        <Sidebar />
        <div className="main__layout">
          <TopNav />

          <div className="content" style={{ marginLeft: "128px" }}>
            <div className="bookings mb-5">
              <div className="booking__wrapper p-5 table-responsive">
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Live Products
                    </button>
                  </li>
                  <li class="nav-item ms-3" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Disabled Products
                    </button>
                  </li>
                </ul>
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <table class="table align-middle" id="AllData">
                      <thead>
                        <tr>
                          <th scope="col">Sr No.</th>
                          <th scope="col">Image</th>
                          <th scope="col">Name</th>
                          <th scope="col">Price</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {materials?.map((i, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                <img
                                  src={'https://apis.mashallahmazangparatha.pk/data/images/products/' + i.image}
                                  alt="img"
                                  className="img-gol"
                                  width="40px"
                                  height="40px"
                                />
                              </td>
                              <td>{i.name}</td>
                              <td>{i.price}</td>
                              <td className="d-flex gap-4 pt-4">
                                
                                <i
                                  class="bi bi-eye text-danger"
                                  style={{ cursor: "pointer",fontSize:'20px' }}
                                  onClick={() => { navigate(`/updtaeProduct/${i.uid}`) }}
                                  // onClick={() => {
                                  //   onDeleteStudent(i.uid);
                                  // }}
                                ></i>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <table class="table align-middle" id="AllData">
                      <thead>
                        <tr>
                          <th scope="col">Sr No.</th>
                          <th scope="col">Image</th>
                          <th scope="col">Name</th>
                          <th scope="col">Price</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {disableProduct?.map((i, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>
                                <img
                                  src={i.image}
                                  alt="img"
                                  className="img-gol"
                                  width="40px"
                                  height="40px"
                                />
                              </td>
                              <td>{i.name}</td>
                              <td>{i.price}</td>
                              <td className="d-flex gap-4 pt-4">
                                
                                <i
                                  class="bi bi-eye-slash text-danger"
                                  style={{ cursor: "pointer",fontSize:'20px' }}
                                  onClick={() => { navigate(`/updtaeProduct/${i.uid}`) }}
                                  // onClick={() => {
                                  //   onDeleteStudent(i.uid);
                                  // }}
                                ></i>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="add_products mt-5">
                <Link to="/add-products">
                  <i class="ri-add-line" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;
