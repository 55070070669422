import React, {useState} from "react";

import {Link, useNavigate} from "react-router-dom";
import "./top-nav.css";


const click=()=>{
  document.querySelector(".sidebar").style.display="block";
  document.querySelector(".content").style.margin_left="0";
}

const TopNav = () => {
  const Navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem('login');
    Navigate('/');
  };

  return (
    <div className="top__nav">
      <div className="top__nav-wrapper">
        <div className="" onClick={click}>
          <div className="hamburger-icon">
            <i className="fas fa-bars"/>
          </div>
        </div>
        <div className="top__nav-right pt-2" >
          <span className="notification">
            <i class="ri-notification-3-line"/>
            <span className="badge">1</span>
          </span>
          <div className={"drop-item"}>
            <i className="fas fa-sign-out-alt"/>
            <p onClick={handleLogout}>Logout</p>
          </div>
          {isDropdownOpen && (
              <div className="dropdown">


              </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopNav;
