
import {Link, useNavigate} from "react-router-dom";
import "../styles/bookings.css";

import Sidebar from "../components/Sidebar/Sidebar";
import TopNav from "../components/TopNav/TopNav";

import React, { useEffect } from 'react'

// import { fetchRequest, fetchSuccess, fetchError } from '../reducers/Action';

import axios from "axios"
import { useState } from "react";
import {message,Modal} from "antd";
// import { useSelector, useDispatch } from 'react-redux'

const Menus = () => {
    const navigate=useNavigate()
    // const materials = useSelector(state => state.values)
    // const dispatch = useDispatch()

    const [materials,setMaterials] = useState([])


    useEffect(() => {

        const params = new FormData();

        params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');

        const config = {

            headers: {

                "Content-Type": "application/x-www-form-urlencoded"

            }

        }

        // dispatch(fetchRequest)

        axios.post('https://apis.mashallahmazangparatha.pk/fetch_menus.php', params, config).then((res) => {


                const materials = res.data.data.menus;

                // dispatch(fetchSuccess(materials))
                setMaterials(materials)
            }

        )

    }, [])

    const onDeleteStudent = (id)=>{
        Modal.confirm({
            title: 'Are you sure you want to delete?',
            onOk:()=>{
                const params = new FormData();
                params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
                params.append('menu_uid', id)

                const config = {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                }


                axios.post('https://apis.mashallahmazangparatha.pk/delete_menu.php', params, config).then(res => {
                    console.log(res.data);
                    if (res.data.data.deleted === true) {
                        message.success("Material Deleted Successfully")
                        window.location.reload(true)
                    }
                })
            }

        })
    }
    return (
        <>
            <div className="layout">
                <Sidebar />
                <div className="main__layout">
                    <TopNav />

                    <div className="content"  style={{marginLeft: '128px'}} >
                        <div>
                            <div className="add_products mt-5">
                                <Link to='/add-menu'>
                                    <i className="ri-add-line"/>
                                </Link>

                            </div>
                        </div>
                        <div className="bookings mb-5">
                            <div className="booking__wrapper p-5">
                                <h2 className="booking__title">Menus</h2>





                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">id_</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                    </thead>
                                    {materials.map((i,index)=>{
                                        return(
                                            <tbody>
                                            <tr>
                                                <td scope="row">{index}</td>
                                                <td >{i.name}</td>
                                                <td >
                                                    <i className="bi bi-pencil-square text-danger" style={{cursor: 'pointer'}} onClick={() => { navigate(`/menuProducts/${i.uid}`) }}/>
                                                    <i className="bi bi-trash3-fill text-danger" style={{cursor: 'pointer'}} onClick={() => {onDeleteStudent(i.uid)}}/>
                                                </td>
                                            </tr>

                                            </tbody>
                                        )})}
                                </table>





                            </div>
                        </div>

                    </div>
                </div>
            </div>


        </>
    );
};

export default Menus;
