
import { Link } from "react-router-dom";
import "../styles/bookings.css";
import Sidebar from "../components/Sidebar/Sidebar";
import TopNav from "../components/TopNav/TopNav";
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

// import { fetchRequest, fetchSuccess, fetchError } from '../reducers/Action';

import axios from "axios"
// import { useSelector, useDispatch } from 'react-redux'






const Profit = () => {
  const navigate = useNavigate()
  // const sale = useSelector(state => state.values)
  // const dispatch = useDispatch()
  const [sale, setSale] = useState([])
  console.log(sale);
  const [query, setQuery] = useState('')


  useEffect(() => {

    const params = new FormData();

    params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');

    const config = {

      headers: {

        "Content-Type": "application/x-www-form-urlencoded"

      }

    }

    // dispatch(fetchRequest)

    axios.post('https://apis.mashallahmazangparatha.pk/fetch_profit_loss.php', params, config).then((res) => {

      const sale = res.data.data.materials
      setSale(sale)
      // dispatch(fetchSuccess(sale))

    }

    )

  }, [])


  return (
    <>
      <div className="layout">
        <Sidebar />
        <div className="main__layout">
          <TopNav />

          <div className="content" style={{ marginLeft: '128px' }} >
            <div className="bookings mb-5">
              <div className="booking__wrapper p-5">
                <h2 className="booking__title">Reports</h2>
                <div className="d-flex justify-content-between">

                  <div>
                    <ul className="d-flex p-0 gx-5">
                      <li>
                        <Link className="float-end btn_sve border-0 py-1 padd-1 text-white rounded " to="/reports">Sales Report</Link>
                      </li>
                      <li className="ps-3">
                        <Link className="float-end  border-0 py-1 background-orange  text-white rounded " to="/profit">Consumption Report</Link>
                      </li>
                      <li className="px-2">
                        <Link className="float-end btn_sve border-0 py-1 padd-1 text-white rounded" to="/sale">Sale by Product </Link>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className='d-flex gap-3'>
                      <form className="form-floating p-0">
                        <input type="datetime-local" className="form-control abc" id="Cstart_date"/>
                        <label htmlFor="Cstart_date">From</label>
                      </form>
                      <form className="form-floating">
                        <input type="datetime-local" className="form-control abc" id="Cend_date" />
                        <label htmlFor="Cend_date">To</label>
                      </form>
                      <button className='btn btn-success border-0 py-2 text-white rounded search-btn'
                              >Search
                      </button>
                    </div>
                  </div>

                </div>

                <input type="search" placeholder="search..." className="tables_serach rounded" onChange={e => setQuery(e.target.value)} />

                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">id_</th>
                      <th scope="col">Name</th>
                      <th scope="col" className={"text-end"}>Remaining Quantity</th>
                      <th scope="col" className={"text-end"}>Consume Quantity</th>
                    </tr>
                  </thead>
                  {sale.filter(i => {
                    if (query === '') {
                      return i
                    }
                    else if (i.name.toLowerCase().includes(query.toLocaleLowerCase())) {
                      return i
                    }
                  }).map((i, index) => {
                    return (
                      <tbody>
                        <tr >
                          <th scope="row">{index}</th>
                          <td>{i.name}</td>
                          <td className={"text-end"}>{i.remaining_quantity}</td>
                          <td className={"text-end"}>{i.consume_quantity}</td>
                        </tr>

                      </tbody>
                    )
                  })}


                </table>
              </div>
            </div>

          </div>
        </div>
      </div>


    </>
  );
};

export default Profit;
