
import { Link } from "react-router-dom";
import "../styles/bookings.css";
import Sidebar from "../components/Sidebar/Sidebar";
import TopNav from "../components/TopNav/TopNav";
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'

// import { fetchRequest, fetchSuccess, fetchError } from '../reducers/Action';

import axios from "axios"
import {message, Modal} from "antd";
// import { useSelector, useDispatch } from 'react-redux'






const Reports = () => {
  const NavLinkStyle = ({ isActive }) => {
    return {
      backgroundColor: isActive ? 'red' : 'blue'
    }
  }
  const navigate = useNavigate()
  // const sale = useSelector(state => state.values)
  // const dispatch = useDispatch()
  const [salemsla, setSalemsla] = useState([])
  const [sale, setSale] = useState([])

  const [query, setQuery] = useState('')
  const [total, setTotal] = useState('')
  const [SCustomer, setSCustomer] = useState({
    Cstart_date: '',
    Cend_date: '',
  })

  const [datecustomer, setDateCustomer] = useState([])

  const handle = (e) => {
    const newCustomer = { ...SCustomer }
    newCustomer[e.target.id] = e.target.value;
    setSCustomer(newCustomer)
  }




  useEffect(() => {

    const params = new FormData();

    params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');

    const config = {

      headers: {

        "Content-Type": "application/x-www-form-urlencoded"

      }

    }

    // dispatch(fetchRequest)

    axios.post('https://apis.mashallahmazangparatha.pk/fetch_sale.php', params, config).then((res) => {

      console.log(res.data,'========')

      const sa = res.data?.data?.sale
      setSalemsla(sa)
setTotal(res.data?.data?.bill);
      // dispatch(fetchSuccess(sale))

    }

    )
  }, [])

  console.log(SCustomer.Cstart_date,'-------->')

  const [newBill,setNewBill]=useState()

  const customerSearch = () => {
    document.getElementById('Rcustomer').classList.add('d-none')
    document.getElementById('Rdcustomer').classList.remove('d-none')
    // document.getElementById('Rdcustomer').classList.add('d-block')


    const params = new FormData();
    params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
    params.append('start_date', SCustomer.Cstart_date);
    params.append('end_date', SCustomer.Cend_date);

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    }

    axios.post('https://apis.mashallahmazangparatha.pk/fetch_specific_sale.php', params, config).then((res) => {
      console.log(res.data)
          const sal = res.data.data?.sale
          setDateCustomer(sal)
      setNewBill(res.data.data?.bill)
          console.log(datecustomer, 'date customer');
        }
    )

  }

  const onDeleteStudent = (id)=>{
    Modal.confirm({
      title: 'Are you sure you want to delete?',
      onOk:()=>{
        const params = new FormData();
        params.append('__api_key__', 'hi,-its-eevee. I can do wonderful things in api creation.');
        params.append('sale_uid', id)

        const config = {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }


        axios.post('https://apis.mashallahmazangparatha.pk/delete_sale.php', params, config).then(res => {
          console.log(res.data);
          if (res.data.data.deleted === true) {
            message.success("Bill Deleted Successfully")
            window.location.reload(true)
          }
        })
      }

    })
  }
  return (
    <>
      <div className="layout">
        <Sidebar/>
        <div className="main__layout">
          <TopNav/>

          <div className="content" style={{marginLeft: '128px'}}>
            <div className="bookings mb-5">
              <div className="booking__wrapper p-5">
                <div className="d-flex justify-content-between align-items-center  ">
                  <div>
                    <h2 className="booking__title">Reports</h2>
                  </div>

                </div>
                <div className="d-flex justify-content-between">
                  <div>
                    <ul className="d-flex align-items-center p-0 gx-5">
                      <li>
                        <Link className="float-end  border-0 py-1 text-white rounded background-orange " to="/reports">Sales
                          Report</Link>
                      </li>
                      <li className="px-2">
                        <Link className="float-end btn_sve border-0 py-1 text-white rounded " to="/profit">Consumption
                          Report</Link>
                      </li>
                      <li className="px-2">
                        <Link className="float-end btn_sve border-0 py-1 text-white rounded " to="/sale">Sale by Product </Link>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className='d-flex gap-3'>
                      <form className="form-floating p-0">
                        <input type="datetime-local" className="form-control abc" id="Cstart_date" onChange={(e) => {
                          handle(e)
                        }}/>
                        <label htmlFor="Cstart_date">From</label>
                      </form>
                      <form className="form-floating">
                        <input type="datetime-local" className="form-control abc" id="Cend_date" onChange={(e) => {
                          handle(e)
                        }}/>
                        <label htmlFor="Cend_date">To</label>
                      </form>
                      <button className='btn btn-success border-0 py-2 text-white rounded search-btn'
                              onClick={customerSearch}>Search
                      </button>
                    </div>
                  </div>
                  <div>


                  </div>
                </div>

                <input type="search" placeholder="search..." className="tables_serach rounded"
                       onChange={e => setQuery(e.target.value)}/>
<div id='Rcustomer'>
                <table className="table " >
                  <thead>
                  <tr>
                    <th scope="col">id_</th>
                    <th scope="col" className={'text-end'}>Total Bill</th>
                    <th scope="col" className={'text-end'}>Created at</th>
                  </tr>
                  </thead>
                  {salemsla.filter(i => {
                    if (query === '') {
                      return i
                    } else if (i?.total_bill.toLowerCase().includes(query.toLocaleLowerCase())) {
                      return i
                    } else if (i?.created_at.includes(query)) {
                      return i
                    }
                  }).map((i, index) => {
                    return (
                        <tbody>
                        <tr>
                          <td>{index+1}</td>
                          <td className={'text-end'}>{i?.total_bill}</td>
                          <td className={'text-end'}>{i?.created_at}</td>
                          <td className={'text-center'}>
                            <button className="btn_sve border-0 py-2 text-white rounded "
                                    onClick={() => {
                                      localStorage.setItem('total_bill',i.total_bill)
                                      navigate(`/view-report/${i['uid']}`)
                                    }}>View Detail
                            </button>
                          </td>
                          <td className="d-flex gap-4 text-end">
                            <i className="bi bi-trash3-fill text-danger" style={{cursor: 'pointer'}} onClick={() => {
                              onDeleteStudent(i.uid)
                            }}></i>
                          </td>
                        </tr>

                        </tbody>
                    )
                  })}


                </table>
                <div className={'text-white '}>
                  <h4>Grand Total: {total}</h4>
                </div>
              </div>
<div className={'d-none'} id='Rdcustomer'>
                <table className="table w-100" >
                  <thead>
                  <tr>
                    <th scope="col">id_</th>
                    <th scope="col" className={'text-end'}>Total Bill</th>
                    <th scope="col" className={'text-end'}>Created at</th>
                  </tr>
                  </thead>
                  {datecustomer?.filter(i => {
                    if (query === '') {
                      return i
                    } else if (i.total_bill.toLowerCaregion_reportse().includes(query.toLocaleLowerCase())) {
                      return i
                    } else if (i.created_at.includes(query)) {
                      return i
                    }
                  }).map((i, index) => {
                    return (
                        <tbody>
                        <tr>
                          <td >{index+1}</td>
                          <td className={'text-end'}>{i?.total_bill}</td>
                          <td className={'text-end'}>{i?.created_at}</td>
                          <td className={'text-center'}>
                            <button className="btn_sve border-0 py-2 text-white rounded "
                                    onClick={() => navigate(`/view-report/${i['uid']}`)}>View Detail
                            </button>
                          </td>
                          <td className="d-flex gap-4 text-end">
                            <i className="bi bi-trash3-fill text-danger" style={{cursor: 'pointer'}} onClick={() => {
                              onDeleteStudent(i.uid)
                            }}></i>
                          </td>
                        </tr>

                        </tbody>
                    )
                  })}


                </table>
                <div className={'text-white '}>
                  <h4>Grand Total: {newBill}</h4>
                </div>
</div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};

export default Reports;
